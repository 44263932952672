import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide9/image/imgPortadaGuia9.png"
import img1 from "@components/pageGuide/guides/guide9/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide9/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide9/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide9/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      Cómo hacer un proceso de ventas en 6 pasos con WhatsApp
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      WhatsApp es un complemento clave para tus campañas de ventas. Se calcula
      que esta aplicación tiene una tasa de apertura de un 98%, es decir, una de
      las más altas dentro de todos los canales de mensajería.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    WhatsApp es un canal muy potente ya que, gracias a su nivel de cercanía con
    los usuarios, hace que tu empresa se acerque más a ellos. Además, está tan
    interiorizada en la vida de las personas que una comunicación por esta app
    reduce las barreras de contacto entre empresa y cliente potencial.
    <br /> <br />
    Por eso, en esta guía aprenderás a utilizar a WhatsApp como una herramienta
    complementaria que te ayudará a mejorar tus procesos de ventas. Y así,
    podrás alcanzar con los objetivos planteados al inicio de tu campaña.
  </p>
)

const data = {
  start: {
    support: "Guía | Telemarketing",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Utiliza WhatsApp durante la prospección",
      },
      {
        key: 2,
        text: "Envío de recordatorios antes del contacto",
      },
      {
        key: 3,
        text: "Seguimiento de leads por WhatsApp",
      },
      {
        key: 4,
        text: "Estrategias para mejorar un proceso de ventas",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
